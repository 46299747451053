import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';


// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#001F5D',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#F6F6F6',
      paper:'#F6F6F6',
      list:'#001F5DB3',
    },
  },
    typography: {
     h1: {
        fontSize: '20px',
        fontWeight: 500,
        fontStyle: 'normal',
        color: '#707070',
        letterSpacing: '0px',
        opacity: '1',
        textAlign: 'center',
        paddingBottom:'19px'
      },
      h2: {
        fontSize: '18px',
        fontWeight: 500,
        fontStyle: 'normal',
        color: '#FFFFFF',
        letterSpacing: '0px',
        opacity: '1',
        textAlign: 'center',
        paddingBottom:'19px',
      },
      h3: {
        fontSize: '16px',
        fontWeight: 400,
        fontStyle: 'normal',
        color: '#04B7A6',
        letterSpacing: '0px',
        paddingBottom:'19px',
      },
      h4: {
        fontSize: '16px',
        fontWeight: 500,
        fontStyle: 'normal',
        color: '#04B7A6',
        letterSpacing: '0px',
        paddingBottom:'19px',
      },
      h5: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "9pt",
        color: "#097CC1"
      },
      h6: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "8pt",
        color: "#707683",
      },    
      subtitle1: {
        fontWeight: 500,
        fontStyle: "normal",
        fontSize: "12pt",
        color: "#323C47",
      },
      subtitle2: {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "12pt",
        color: "#707683",
      },
      body1: {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "16px",
      },
      body2: {
        fontWeight: 400,
        fontStyle: "normal",
        fontSize: "11px",
        textAlign: 'justify',
      },
      p: {
        fontSize: '15px',
        fontWeight: 300,
        fontStyle: 'normal',
        color: '#707070',
        letterSpacing: '0px',
        opacity: '1',
      },
      SubtitleHeader: {
        fontSize: '16px',
        fontWeight: 500,
        fontStyle: 'normal',
        color: '#FFFFFF',
        letterSpacing: '0px',
        opacity: '1',
        textAlign: 'center',
        paddingBottom:'19px',
      },   
    },   

  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1', 
          h2: 'h2', 
          h3: 'h3', 
          h4: 'h4', 
          h5: 'h5', 
          h6: 'h6', 
          subtitle1: 'h6',
          subtitle2: 'h6', 
          body1: 'body1', 
          body2: 'p', 
          inherit: 'p',
          p:'p',
        },
      },
      styleOverrides: {
        root: {
          fontFamily: "'Roboto', sans-serif",
          '& span': {
            fontSize: '15px',
            fontWeight: 400,
            fontStyle: 'normal',
            color: '#0052DB',
            letterSpacing: '0px',
            opacity: '1',
        }
        },
        colorfuente: {
          color: '#cd3b63',
        },
        negra: {
            fontSize: '15px',
            fontWeight: 600,
            fontStyle: 'italic',
            color: '#707070',
            letterSpacing: '0px',
            opacity: '1',
        },
        negrachi: {
          fontSize: '13px',
          fontWeight: 600,          
          color: '#707070',
          letterSpacing: '0px',
          opacity: '1',
        },
        chica: {
          fontSize: '13px',
          fontWeight: 300,          
          color: '#707070',
          letterSpacing: '0px',
          opacity: '1',
        },
          negrasi: {
            fontSize: '15px',
            fontWeight: 600,
            color: '#707070',
            letterSpacing: '0px',
            opacity: '1',
            margin:'0px',
            padding:'0px'
        },
        estados: {
          fontSize: '14px',
          fontWeight: 300,
          fontStyle: 'italic',
          color: '#707070',
          letterSpacing: '0px',
          opacity: '1',
      },    
        listas:{
          fontSize: '13pt',
          paddingLeft: '18px',
          color:'#ffffff',
        },
        listaUsu:{
          fontSize: '9pt',
          color:'#ffffff',
        }
     },      
    },
    MuiInputBase:{
      styleOverrides: {
        input: {
          fontSize: '12pt',
        },
     },
    },
    MuiButton:{
      
      styleOverrides: {
        root: {
          fullWidth:true,
        },
        iconSizeMedium: {
          "& > *:first-of-type.finicon": {
            fontSize: '30px',
            margin:'0px',
          }
        },

      },
    },
    MuiDataGrid:{
      styleOverrides: {
        root: {
          fontFamily: "'Roboto', sans-serif",
          fontSize:'14px',
          textAlign:'left',
          color: '#707070',
          opacity: '1',
          fontWeight: '300',
          borderStyle:'none',
          height: 'calc(100dvh - 140px)', // Alto de la ventana
          minHeight: 'calc(100dvh - 140px)',
          width: '100dvw', // Ancho de la ventana
          maxWidth:'100dvw',
          padding:'0px',
                    
        },
        sx:{
          fontSize: '12px',
          textAlign:'left',
          color: '#707070',
          opacity: '1',
          fontWeight: '300',  
        },   
      },
    },
    MuiTab:{
      styleOverrides:{       
        root:{
          color:'#9F9F9F',
          "&:hover": {
            color:'#0080FF'
          },
          '&.Mui-selected': {
            color:'#0080FF'
          }
        },        
      },    
  },
    MuiTabs:{
      styleOverrides:{
        indicator: {
          backgroundColor:'#0080FF',
        }
      },    
  },
    MuiAvatar:{
      styleOverrides:{
        root:{
          color:'#001F5DB3',
          background: '#ffffff',
          margin: 1,
          width: 35,
          height: 35,
        }
      }
    },

    MuiSelect:{
      styleOverrides:{
      root:{
        color:'#FFFFFF',
        backgroundColor: '#0080FF',
        width: '100%', 
        height: 50,
        opacity:'50%',
        transformOrigin:'center'
      }
    }
    },
  },
    
});


export default theme;
