import {  useReducer, useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import { authReducer } from './authReducer';
import { axiosInstance } from '../../helpers/axios';


const AUTH_INITIAL_STATE = {
        token: "",
        checking: true
    
}



export const AuthProvider = ({ children }) => {

       const [state, dispatch] = useReducer(authReducer, AUTH_INITIAL_STATE);
       const [user, setUser] = useState(null); 

      
      const signIn = (token, user) => {
            setUser(user);
            localStorage.setItem('token', token);
            return dispatch({type: '[Auth] - Login', payload: {token, checking: false}})
      }

       const logout = () => {
              localStorage.removeItem('token');
              setUser(null);
              return dispatch({type: '[Auth] - Logout', payload: {}});
       }

       useEffect(() => {
        dispatch({type: '[Auth] - StartChecking', payload: {}});
        const token = localStorage.getItem("token") || null;
        if(!token) {
              dispatch({type: '[Auth] - EndChecking', payload: {}});
              return; 
        }
        

        const renewToken = async() => {
             try {
              const response = await axiosInstance.post('/auth/renew/', {}, {
                     headers: {'Authorization': `Token ${token}`
                     }
              })
              if(!response || response.status !== 200) console.log("Algo salió mal")

              const {token: newToken} = response.data;
              const {user: user} = response.data;
              localStorage.setItem("token", newToken);
              signIn(newToken, user);
             } catch (error) {
              dispatch({type: '[Auth] - EndChecking', payload: {}});
              console.log(error)
             }
        }
        

        renewToken();
       }, [])

       

       return (
        <AuthContext.Provider value={{
               ...state,
               signIn,
               logout,
               user
               
             
        }}>
               {children}
        </AuthContext.Provider>
 )
}