import React, { useContext, lazy, useState, useEffect, Suspense} from 'react';
// import Login from './components/Login';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
// import Main from './components/Main';
import { AuthContext } from './context/Auth/AuthContext';
import Typography from '@mui/material/Typography';

const Login =  lazy(() => import('./components/Login'))
const Main = lazy(() => import('./components/Main'))



function Loading() {
  return (
  <Typography sx={{padding:'20px'}} component="h1" variant='h4'>
  {"Cargando..."}
</Typography>
  )
}

const COMPONENTS = {
  "Login": <Login/>,
  "Main": <Main/>
}

export default function App() {
  const {token = null, setToken, checking} = useContext(AuthContext)
  const [isLoadingApp, setIsLoadingApp] = useState(true);
  if(checking){
    
    return(
      <Typography sx={{padding:'20px'}} component="h1" variant='h4'>
      {"Cargando..."}
    </Typography>
    )
  }

 


  return (
            <BrowserRouter>
             <Suspense fallback={<Loading />}>
              <Routes>
                {
                  
                  <Route path="*" element={token?COMPONENTS["Main"]:COMPONENTS["Login"]}></Route>
                }
                </Routes>
                </Suspense>
            </BrowserRouter>
  );
}

