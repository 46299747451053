import  React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { AuthProvider } from './context/Auth/AuthProvider';


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);



root.render(
  <AuthProvider>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />        
        <App />
    </ThemeProvider>
  </AuthProvider>

);
