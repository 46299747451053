export const authReducer = (state, action) => {

    switch (action.type) {
        case '[Auth] - Login':
            return {
                ...state, 
                ...action.payload
            }
        case '[Auth] - StartChecking':
            return {
                ...state,
                checking: true
            }

        case '[Auth] - EndChecking':
                return {
                    ...state,
                    checking: false
                }
    
        case '[Auth] - Logout':
            return {
                ...state,
                token: ""
            }
        
    
        default:
            return state;
        }
    }